<template>
    <!-- <header /> -->
    <div id="Sites" class="second-section">
        <div class="second-section__title--container">
            <h1 class="second-section__title">Modules</h1>
        </div>
        <div class="second-section__main--container">
            <swiper
                :slides-per-view="1"
                :breakpoints="{
                    1000:{
                        slidesPerView: 3,
                        spaceBetween: 5
                    },
                    680: {
                        slidesPerView: 2.5,
                        spaceBetween: 5
                    },
                    570:{
                        slidesPerView: 2.05,
                        spaceBetween: 5
                    }
                }"
                :centered-slides-bounds="true"
                :space-between="10"
                :autoplay="{delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true}"
                :modules="modules"
                :speed="1100"
                :effect="cowerflow"
                :coverflow-effect="{rotate: 30, slideShadow: true}"
                :loop="true"
                :pagination="{type:'bullets', clickable: true}"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                class="second-section__carousel--container"
            >
            <swiper-slide v-for="(item,index) in sliderItems" :key="index">
                <carouselItem 
                    :item_data="item"
                />
            </swiper-slide>
            </swiper>
        </div>
    </div>
    <!-- <button @click="nextSlide()">Next</button>
    <button @click="prevSlide()">Prev</button> -->
</template>

<script>
/* eslint-disable */
import carouselItem from "./carousel-item"
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Mousewheel, EffectCoverflow, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css'
export default {
    data(){
        return {
            sliderItems : [
                // {"id":1,"title":"Binance","desc":"Mystery Box Mint","img":"binance.png"},
                
                {id:1,title:"Solana",desc:"Token sniper",img:"solana.png"},
                {"id":2,"title":"MagicEden","desc":"Solana Mint","img":"magiceden.png"},
                // {id:3,title:"Binance",desc:"P2P sniper",img:"binance.png"},
                {"id":3,"title":"MagicEden","desc":"BTC Mint","img":"magiceden.png"},
                // {id:5,title:"Cardano",desc:"Mint",img:"cardano.png"},
                {"id":4,"title":"MagicEden","desc":"ETH Mint","img":"magiceden.png"},
                {"id":5,"title":"MagicEden","desc":"Polygon Mint","img":"magiceden.png"},
                {id:6,title:"Kucoin",desc:"Nft/token/alloc mint",img:"kucoin.png"},
                {id:7,title:"Solana",desc:"Candymachine Mint",img:"solana.png"},
                {id:8,title:"Solana",desc:"Elixir Mint",img:"solana.png"},
                // {"id":8,"title":"MagicEden","desc":"Polygon Mint","img":"magiceden.png"},
                
                // {id:4,title:"Binance",desc:"Market sniper",img:"binance.png"},
                // {id:6,title:"Atomichub",desc:"Assets Mint",img:"atomichub.png"},
                // {id:7,title:"Cardano",desc:"Mint",img:"cardano.png"},
                // {id:8,title:"Near",desc:"Mint",img:"near.png"},
                // {id:9,title:"Kucoin",desc:"Mint",img:"kucoin.png"},
                // {id:10,title:"Bybit",desc:"Mint",img:"bybit.png"},
                // {"id":11,"title":"MagicEden","desc":"Launchpad Mint","img":"magiceden.png"},
                // {"id":12,"title":"Binance","desc":"Market NFT Mint","img":"binance.png"},
                // {id:13,title:"Solana",desc:"Monkelabs Mint",img:"solana.png"},
                // {id:14,title:"Atomichub",desc:"Assets Sniper",img:"atomichub.png"},
            ],
            modules: [Autoplay, Mousewheel, EffectCoverflow, Pagination],
            previousSlide:null
        }
    },
    mounted(){
    },
    components:{
        carouselItem,
        Swiper,
        SwiperSlide
    },
    computed : {
            getSlider(){
                //this.sliderItems[this.currentSlide+2].isMain = true
                return this.getSlide(this.sliderItems,this.currentSlide,3)
            },
    },
    methods:{
        getSlide(arr, n, maxSlide=3){
            let value = arr.slice(n, n + maxSlide)
            const len = value.length

            if (n < 0){
                return this.getSlide(arr, arr.length + n)
            }

            if (n > arr.length){
                return this.getSlide(arr, n - arr.length)
            }

            if (len < maxSlide){
                value.push(...arr.slice(0, maxSlide - len))
            }

            return value
        },
        nextSlide(){
            this.currentSlide++
        },
        prevSlide(){
            this.currentSlide--
        }
    }
}
</script>

<style lang="scss">
    .second-section__carousel--container{
        width:100%;
        display:flex;
        flex-direction: column;
        gap:41px;
        height:100%;
        transition: all 2s ease;
        max-width: 1433px;
        margin: 0 auto;
        padding: 20px 0;
    }
    
    .swiper-pagination{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .swiper-pagination-bullet.swiper-pagination-bullet-active{
        width: 14px;
        height: 14px;
        opacity: 1;
        &::before{
            position: absolute;
            width: 10px;
            height: 10px;
            content: "";
            background: #4361EE;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-54%, -53%);
        }
    }
    
    .swiper-pagination-bullet{
        position: relative;
        transition: all .3s ease;
        width: 8px;
        height: 8px;
        border: 1px solid gray;
        background: transparent;
        opacity: 0.5;
    }

    .second-section{
        display:flex;
        flex-direction:column;
        margin-top:11px;
        // color:white;
    }

    .second-section__main--container{
        margin: 90px 0 0 0;
        align-self:center;
        width:100%;
        height:215px;
    }

    .second-section__title--container{
        padding: 0 133px;
    }
    
    .second-section__title{
        color:white;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 47px;
        line-height: 64px;
        letter-spacing: 2.22px;
        color: #FFFFFF;
    }

    .second-section__main__cardrows{
        display:flex;
        gap:23.92px;
    }

    .second-section__main__cardrows__firstrow--container{
        display:flex;
        flex-direction:column;
        gap:25.51px;
        width:447.08px;
    }

    .second-section__main__cardrows__secondrow--container{
        display:flex;
        flex-direction:column;
        gap:25.51px;
        width:447.08px;
    }
    
    @media screen and (max-width: 850px) {
        .second-section__title--container{
            padding: 0;
            display: flex;
            justify-content: center;
        }
        .second-section__title{
              font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            flex:0.24;
            line-height: 34px;
            /* identical to box height, or 105% */

            font-feature-settings: 'salt' on, 'liga' off;
            display:flex;
            justify-content:flex-end;
            color: #F2F2F2;
        }
    }
    
    @media screen and (max-width: 571px) {
        
        .card{
            max-width: 354px;
        }
        
        .swiper{
            max-width: 80%;
        }
    }
    
    @media screen and (max-width: 400) {
        .second-section__title{
             font-family: 'Inter' !important;
            font-style: normal !important;
            font-weight: 800 !important;
            font-size: 20.5px !important;
            line-height: 34px !important; 
            font-feature-settings: 'salt' on, 'liga' off !important;
            color: #F2F2F2 !important;
        }
    }



// @font-face {
//          font-family:Poppins;
//         src: url(../../../assets/fonts/poppins/Poppins-Regular.ttf);
// }

</style>