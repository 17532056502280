

<template>
  <div :class="['alert', {'alert--hidden': hidden}]" @drag="startClick" @drop="startClick">
      <div class="alert__inner">
          <div class="alert__info">
            <div class="alert__title">{{title}}</div>
            <div class="alert__info--bottom">
              <svg :class="['alert__icon', 'alert__icon--small', getColor]" fill="none" width="20" height="21" viewBox="0 0 20 21">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M10.0003 18.208C14.257 18.208 17.7087 14.7572 17.7087 10.4997C17.7087 6.24301 14.257 2.79134 10.0003 2.79134C5.74366 2.79134 2.29199 6.24301 2.29199 10.4997C2.29199 14.7572 5.74366 18.208 10.0003 18.208Z"
                       stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.79883 13.3926L11.7038 10.5001L8.79883 7.60758" stroke-linecap="round"
                      stroke-linejoin="round" />
              </svg>
              <div :class="['alert__subtitle', getColor]">{{subtitle}}</div>
            </div>
          </div>
          <img class="alert__icon alert__icon--big" :src="getIcon('big')">
      </div>
  </div>
</template>

<script>
import other from '../../assets/alert/other.png'
import success from '../../assets/alert/success.png'
import fail from '../../assets/alert/fail.png'
import { mapActions } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "success"
    },
    alertId: {
      type: Number,
      required: true
    },
    time: {
      type: Number,
      required: true
    }
  },
  data(){
    return {
      icons: {
        success: {
          big: success,
        },
        fail: {
          big: fail,
        },
        other: {
          big: other,
        },
      },
      hidden: false
    }
  },
  computed: {
    getColor(){
      return `alert__${this.type}`
    }
  },
  created() {
    setTimeout(() => {
      this.hidden = true
      setTimeout(() => {
        this.DELETE_ALERT(this.alertId)
      }, 500)
    }, 1000 * this.time)
  },
  methods: {
    ...mapActions(["DELETE_ALERT"]),
    getIcon(type){
        return this.type.length > 0 && this.icons?.[this.type]?.[type] ? this.icons[this.type][type] : this.icons.success[type]
    },
    startClick(){
      console.log(true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .alert{
    z-index:500;
    // position:absolute;
    cursor: pointer;
    margin-top:10px;
    -webkit-app-region: drag;
    background: #222941;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.46);
    border-radius: 8px;
    min-width: 400px;
    min-height: 110px;
    transition: transform .5s ease-out;
    animation: show .5s forwards ease-in-out;
    
    @keyframes show {
      from{
        transform: translateX(500px);
      }
      to{
        transform: translateX(0px);
      }
    }
    
    @keyframes hidden {
      from{
        transform: translateX(0px);
        opacity: 1;
      }
      to{
        transform: translateX(500px);
        opacity: 0;
      }
    }
    
    &__inner{
      user-select: none;
      padding: 24px 40px 27px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    &__icon{
      object-fit: contain;
      &--big{
        width: 46px;
        height: 46px;
      }
    }
    
    &__success{
      color: #8FE75F;
      stroke: #8FE75F;
    }
    
    &__fail{
      color: #FF7E72;
       stroke: #FF7E72;
    }
    
    &__other{
      color: #7793EF;
       stroke: #7793EF;
    }
    
    &--hidden{
       animation: hidden .5s forwards ease-in;
    }
    
    &__title{
      font-family: 'Museo';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 130%;
      color: #FFFFFF;
    }
    
    &__info{
      &--bottom{
        margin-top: 8px;
        display: flex;
        gap: 10.29px;
      }
    }
    
    &__subtitle{
      font-family: 'Museo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
    }
  }
</style>