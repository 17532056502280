//import axios from 'axios'
import router from './router'
import {createStore} from 'vuex'
//import axios from 'axios'

//import Vue from 'vue'
//import cookies from 'vue-cookies'

//let host = 'http://127.0.0.1:3031'

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.authorization = cookies.get('aToken') || ''
// let host = 'http://localhost'



export default createStore({
    state: {
        discord:{},
        user:{},
        alerts:[],
        accessToken: localStorage?.["accessToken"]  //cookies.get('aToken') || '',

    },
    mutations: {
        SET_BEARER(state, payload){
            state.accessToken = payload
        },
        SET_DISCORD(state, payload){
            state.discord = payload
        },
        SET_USER(state, payload){
            state.user = payload
        },
        SET_WALLET(state, payload){
            state.wallet = payload
        },
        DELETE_ALERT(state, payload){
            state.alerts = state.alerts.filter(item => item.id !== payload)
          },
        ADD_ALERT(state, payload){
          state.alerts.push(payload)
        }
    },
    actions: {
        DELETE_ALERT({commit}, id){
            commit("DELETE_ALERT", id)
          },
          ADD_ALERT({commit, state}, payload){
            let id 
            do{
              id = Date.now() * Math.random()
            }while(state.alerts.find(item => item.id === id))
            commit("ADD_ALERT", {
              ...payload,
              date: Date.now(),
              id,
            })
          },
        SET_WALLET({commit}, wallet){
          
            commit("SET_WALLET", wallet)
        },
        async getUser ({commit,state}){
            let r = await(await fetch(`https://api.cactusweb.io/api/v2/user/license/Vision%20AIO`,{
                method:"GET",
                headers:{
                    "authorization":`Bearer ${state.accessToken}`
                }
            })).json()
            if(!r?.key){
                return router.push({ path: `/bind` })
            }
            commit('SET_USER',r)
        },
        setBearer({commit},bearer){
            try{
              //var r = await axios(`${host}/api/verifyToken`, { method:"POST", data: {token: state.dToken} })
                commit('SET_BEARER', bearer)
            }catch(err){
              return
            }
        },
        setDiscord({commit},discord){
            try{
              //var r = await axios(`${host}/api/verifyToken`, { method:"POST", data: {token: state.dToken} })
                commit('SET_DISCORD', discord)
            }catch(err){
              return
            }
        },
        setUser({commit},user){
            try{
              //var r = await axios(`${host}/api/verifyToken`, { method:"POST", data: {token: state.dToken} })
                commit('SET_USER', user)
            }catch(err){
              return
            }
        },
        logoutDiscord({commit}){
            try{
              //var r = await axios(`${host}/api/verifyToken`, { method:"POST", data: {token: state.dToken} })
                localStorage["accessToken"] = ''
                commit('SET_BEARER', "")
                return router.push({ path: `/` })
            }catch(err){
                return
            }
        }
    },
    getters: {
        getToken: store =>{
            return store.accessToken
        },
        getUser: store =>{
            return store.user
        },
        getDiscord: store =>{
            return store.discord
        },
        getAlerts: store => {
            return store.alerts
        }
    }
})