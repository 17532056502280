<template>

  <!-- <v-app> -->
    <!-- <div
      class="wrapper"
    > -->
      <!-- <discordBar/>  -->
      <router-view>

      </router-view> 
      <div class="alerts-container">
        <alert v-for="al of getAlerts" :key="al.id" :title="al.title" :subtitle="al.subtitle" :alertId="al.id" :type="al.type" :time="al.time"/>
      </div>
    <!-- </div> -->

  <!-- </v-app> -->
</template>



<script>
/* eslint-disable */

import {  mapGetters } from 'vuex';
import alert from "./components/all/alert.vue"

export default {
  name: 'App',

  components: {
      alert
    // discordBar
  },
  computed: {
    ...mapGetters(['getToken','getAlerts'])
  },
  methods: {
    //...mapActions([ 'verifyToken'])
  },
  async created(){
    // await this.SET_WALLET(useWallet())
  //  setInterval(async() => {
  //     if(this.getToken.length > 1){
  //       await this.verifyToken()
  //     }
  //  }, 10000)
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
  // @font-face {
  //   font-family: "Museo";
  //   src: url("~@/assets/fonts/Museo.ttf") format("truetype");
  // }
  #app{
    min-height: 100vh;
    width:100%;
    position:relative;
    overflow:hidden;
    background-color:#121212;
  }
  
  *{
    padding: 0;
    margin: 0;
    font-family: 'Poppins';
    box-sizing: border-box;
  }
  
  .alerts-container{
    display: flex;
    flex-direction: column;
    gap: 28px;
    position: absolute;
    bottom: 34px;
    right: 30px;
  }

  ul{
    list-style: none;
  }

  @font-face {
      font-family: 'Poppins';
      src: url("assets/fonts/poppins/Poppins.ttf");
  }

  @font-face {
      font-family: 'Gotham';
      src: url("assets/fonts/gotham/GothamBook.ttf");
  }

  @font-face {
      font-family: 'Inter';
      src: url("assets/fonts/inter/Inter-Regular.ttf");
  }

    @font-face {
      font-family: 'Open Sans';
      src: url("assets/fonts/open-sans/OpenSans.ttf");
  }

  body::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
  }

  body::-webkit-scrollbar
  {
    width: 10px;
    background-color: #F5F5F5;
  }
  body::-webkit-scrollbar-thumb
  {
	  border-radius: 10px;
	  background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0.44, rgb(122,153,217)),color-stop(0.72, rgb(73,125,189)),color-stop(0.86, rgb(28,58,148)));
  }
</style>