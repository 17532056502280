import {createRouter,  createWebHistory} from 'vue-router'
import landing from "./pages/landing/landing"
import dashboard from "./pages/dashboard/dashboard"
import bind from "./pages/bind/bind"
import store from "./store"


const routes = [
    {
        path: '/',
        component:landing,
        name:"landing",
        meta: {
            requiresAuth: false
        }
    },
    // {
    //     path: '/login',
    //     component:loginPage,
    //     meta:{
    //         withoutToken: true
    //     }
    // },
    {
        path: '/bind', 
        component: bind,
        name:"bind",
        meta: {
            requiresAuth: true
        }
    },
    { 
        path: '/dashboard', 
        name:"dashboard",
        component: dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/:pathMatch(.*)*',
        redirect:"/"
    }
]


let router = createRouter({history: createWebHistory(), routes})
router.beforeEach(async (to, from, next) => {

    var accessToken = localStorage?.["accessToken"]
    //console.log(to)
    if(to?.query?.accessToken){
        store.dispatch('setBearer',to?.query?.accessToken)
        localStorage["accessToken"] = to?.query?.accessToken
        if(to?.query?.redirect_to){
            next(`/${to?.query?.redirect_to.slice(1,-1)}`)
            return
        }else{
            next("/")
            return
        }
        
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (accessToken) {
            try{
                let r = await(await fetch(`https://api.cactusweb.io/api/v2/user/@me`,{
                    method:"GET",
                    headers:{
                        "authorization":`Bearer ${store.getters.getToken}`
                    }
                })).json()
                if(!r?.avatar){
                    throw new Error("")
                }
                store.dispatch('setDiscord',r)
                let r1 = await(await fetch(`https://api.cactusweb.io/api/v2/user/license/Vision%20AIO`,{
                    method:"GET",
                    headers:{
                        "authorization":`Bearer ${store.getters.getToken}`
                    }
                })).json()
                if(to?.name === 'dashboard'){

                    if(!r1?.key){
                        console.log('bind')
                        return next("/bind")
                    }
                    store.dispatch('setUser',r1)
                }
                if(to?.name === "bind"){
                    if(!r1?.key){
                        //invalid key
                        return next()
                    }
                    store.dispatch('setUser',r1)
                    return next("/dashboard")
                }

                
            }catch(err){
                store.dispatch('setBearer',"")
                localStorage["accessToken"] = ""
                return next("/") 
            }



            return next()
        }
        // console.log("login 2")
        window.location = `https://api.cactusweb.io/api/v2/user/auth?redirect_to=dashboard`
        return
    }
    if (to.matched.some(record => record.meta.withoutToken)) {
        // if (store.getters.getToken) {
        //     return next("/")
        // }
        return next()
    }
    next()
    // store.dispatch('verifyToken').then(() => {
    //     if (to.matched.some(record => record.meta.requiresAuth)) {
    //         if (store.getters.getToken) {

    //             return next()
    //         }
    //         // console.log("login 2")
    //         window.location = `https://discord.com/api/v9/oauth2/authorize?client_id=809535097157451807&response_type=code&redirect_uri=https%3A%2F%2Fapi.cactusweb.io%2Fapi%2Fv2%2Fuser%2Fauth%2Ftoken&scope=identify%20email%20guilds%20guilds.join`
    //         return
    //     }
    //     if (to.matched.some(record => record.meta.withoutToken)) {
    //         if (store.getters.getToken) {
    //         return next("/")
    //         }
    //         return next()
    //     }
    //     next()
    // }).catch(() => {
    //     window.location = `https://discord.com/api/v9/oauth2/authorize?client_id=809535097157451807&response_type=code&redirect_uri=https%3A%2F%2Fapi.cactusweb.io%2Fapi%2Fv2%2Fuser%2Fauth%2Ftoken&scope=identify%20email%20guilds%20guilds.join`
    //     return
    //     //console.log("login 1")
    //     //next('/login')
    // })
})

export default router