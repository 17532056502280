<template>
        <div :class="['card', 'flicking-panel', {left:left}, {right:right}, {main:main}]">
            <div class="card__firstrow--container">
                <img class="card__img" :src="require(`../../../assets/landing/section/${item_data.img}`)" />
            </div>
            <div class="card__secondrow--container">
                <div class="card__secondrow__title">{{item_data.title}}</div>
                <div class="card__secondrow__desc">{{item_data.desc}}</div>
            </div>
        </div>
</template>

<script>
/* eslint-disable */

export default {
    name:"carousel-item",
    props:{
        item_data:{
            type:Object
        },
        main:Boolean,
        left:Boolean,
        right:Boolean
    },
    data(){
        return {

        }
    },
    components:{
       
    },
    methods:{

    }
}
</script>

<style lang="scss">
    .card.main{
        max-width:447px;
        // min-width:447px;
        height:159.49px;
        border: 1.2299px solid #DEDEDE;
        /* 1 */
        transform: scale(1.1);

        backdrop-filter: blur(30px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 12.299px;
    }

    // .card.visible{
    //     display:flex;
    // }

    .left{
        opacity: 0.15;
        border: 1.15265px solid #DEDEDE;
        backdrop-filter: blur(28.1157px);
        border-radius: 11.5265px;
        max-width:419px;
        height:149.48px;
    }

    .right{
        border: 1.11688px solid #DEDEDE;
        backdrop-filter: blur(27.2434px);
        /* Note: backdrop-filter has minimal browser support */
        max-width:406px;
        min-width:406px;
        height:144.84px;
        border-radius: 11.1688px;
    }
    
    .swiper-slide{
        // transition: all .7s ease;
        padding: 0 15px;
    }
    
    .swiper-slide-next > .card{
        transition: transform .5s ease;
        // backdrop-filter: blur(30px);
        // background: linear-gradient(294.65deg, rgba(121, 199, 247, 0.3) -2.14%, rgba(255, 255, 255, 0.3) 100.06%);
        transform: scale(1.1);
    }
    
    .swiper-slide-prev{

    }
    
    .swiper-slide:not(.swiper-slide-next) > .card{
        // backdrop-filter: blur(28.1157px);
        opacity: 0.50;
    }

    .card{
        background: linear-gradient(294.65deg, rgba(121, 199, 247, 0.3) -2.14%, rgba(255, 255, 255, 0) 100.06%);
        border-radius: 11.1688px;
        display:flex;
        //display:none;
        align-items: center;
        position: relative;
        // filter: blur(0.5px);
        // backdrop-filter: blur(30px);
        width:100%;
        max-width:419px;
        overflow: hidden;
        height:149.49px;
        padding:49px;
        border: 1.2299px solid #DEDEDE;
    }
    
    .card__img{
        max-width: 60px;
        height: 60px;
        object-fit: contain;
    }

    .card__secondrow--container{
        display:flex;
        flex-direction:column;
        flex:1;
        padding: 0 0 0 28.29px;
        justify-content:center;
        
    }

    .card__secondrow__title{
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 500;
        font-size: 19.6784px;
        line-height: 30px;
        letter-spacing: 0.24598px;
        color: #FFFFFF;
    }

    .card__secondrow__desc{
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 400;
        font-size: 19.6784px;
        line-height: 30px;
        letter-spacing: 0.12299px;
        color: #FFFFFF;
    }
    
    @media screen and (max-width: 1000px) {
        .card{
            padding: 40.61px;
        }
        // .swiper-slide-active > .card{
        //     margin: 0 auto;
        //     transition: transform .5s ease;
        //     transform: scale(1.1);
        // }
        // .swiper-slide.swiper-slide-active > .card{
        // // backdrop-filter: blur(28.1157px);
        //     opacity: 1;
        // }
        .swiper-wrapper{
            left: -100px;
        }
    }
    
    @media screen and (max-width: 680px) {
        .swiper-wrapper{
            left: -135px;
        }
    }
    
     @media screen and (max-width: 571px) {
         .swiper-wrapper{
            left: 0;
        }
         .swiper-slide-active > .card{
            margin: 0 auto;
            transition: transform .5s ease;
            transform: scale(1.1);
        }
         .swiper-slide.swiper-slide-active > .card{
        // backdrop-filter: blur(28.1157px);
            opacity: 1;
        }
        
        .card__secondrow__desc,.card__secondrow__title{
            font-size: 15.6784px;
        }
     }


</style>