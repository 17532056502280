<template>

    <div class="dashboard"> 
        <div class="background__form" @click="hidePayForm" v-if="payForm"></div>
            <div class="paymentForm" v-if="payForm">
                <div class="paymentForm--flex"></div>
                <div class="paymentForm--main" v-if="payFormStage == 'main'">
                    <div class="paymentForm__title--container">
                        <div class="paymentForm__title">Select payment method</div>
                    </div>
                    <div class="paymentForm__payment--container main">
                        <div class="paymentForm__payment">
                            <div class="paymentForm__payment__button--inner main" @click="showSolana">
                                <!-- <div class="paymentForm__payment__flex"></div> -->
                                <div class="paymentForm__payment__image--container">
                                    <img class="paymentForm__payment__image" src="../../assets/dashboard/pay__solana.svg" />
                                </div>
<!--                                 
                                <div class="paymentForm__payment__button" >SOL</div> -->
                            </div>
                        </div>
                        <!--
                        <div class="paymentForm__payment">
                            <div class="paymentForm__payment__button--inner main" @click="showBinance">
                                <div class="paymentForm__payment__flex"></div>
                                <div class="paymentForm__payment__image--container">
                                    <img class="paymentForm__payment__image" src="../../assets/dashboard/pay__binance.svg" />
                                </div>
                                <div class="paymentForm__payment__button" >BEP20</div>
                            </div>
                        </div>
                        <div class="paymentForm__payment">
                            <div class="paymentForm__payment__button--inner main" @click="showTron">
                                <div class="paymentForm__payment__flex"></div>
                                <div class="paymentForm__payment__image--container">
                                    <img class="paymentForm__payment__image" src="../../assets/dashboard/pay__trc.svg" />
                                </div>
                                
                                <div class="paymentForm__payment__button" >TRC20</div>
                            </div>
                        </div>
                        -->

                        <!-- <div class="paymentForm__payment">
                            <div class="paymentForm__payment__button--inner main" @click="showGarantex">
                                <div class="paymentForm__payment__flex"></div>
                                <div class="paymentForm__payment__image--container">
                                    <img class="paymentForm__payment__image" src="../../assets/dashboard/pay__garantex.png" />
                                </div>
                                
                                <div class="paymentForm__payment__button" >RUB</div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!--
                
                <div class="paymentForm--binance" v-if="payFormStage == 'binance'">
                    <div class="paymentForm__row--binance">
                        <div class="paymentForm__title--container binance">
                            <div class="paymentForm__title binance">To complete the operation, transfer {{this.renewInformation?.Price}} USDT/BUSD</div>
                        </div>
                        <div class="paymentForm__payment--container binance">
                            <div class="paymentForm__payment binance">
                                <div class="paymentForm__payment__column">
                                    <div class="paymentForm__payment__title--inner">
                                        <div class="paymentForm__payment__title">BEP20 Address:</div>
                                    </div>
                                    <div class="paymentForm__payment__address--container">
                                        <div class="paymentForm__payment__address--inner">
                                            <input class="paymentForm__payment__address" disabled :value="renewInformation?.BinanceBep20" />
                                        </div>
                                        <div class="paymentForm__payment__address__copy--inner">
                                            <img class="paymentForm__payment__address__copy" src="../../assets/dashboard/copyAddress.svg" />
                                        </div>
                                    </div>
                                </div>
                                <div class="paymentForm__payment__column">
                                    <div class="paymentForm__payment__title--inner">
                                        <div class="paymentForm__payment__title input">Input your TxID/TxHash/TxLink</div>
                                    </div>
                                    <div class="paymentForm__payment__input--inner">
                                        <input class="paymentForm__payment__input" placeholder="Input your tx id here" v-model="transactionInput" />
                                    </div>

                                </div>
 
                                <div class="paymentForm__payment__button--inner pay" @click="checkBinanceRenew">
                                    <div class="paymentForm__payment__button" >Renew</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paymentForm__row--binance">
                        <qrcode-vue :value="renewInformation?.BinanceBep20" :size="size" level="H" />
                    </div>
                </div>
                -->
                <!-- <div class="paymentForm--binance" v-if="payFormStage == 'garantex'">
                    <div class="paymentForm__row--binance">
                        <div class="paymentForm__title--container binance">
                            <div class="paymentForm__title binance">To complete the operation, enter {{this.renewInformation?.PriceRUB}} RUB garantex code</div>
                        </div>
                        <div class="paymentForm__payment--container binance">
                            <div class="paymentForm__payment binance">

                                <div class="paymentForm__payment__column">
                                    <div class="paymentForm__payment__title--inner">
                                        <div class="paymentForm__payment__title input">Input your garantex code</div>
                                    </div>
                                    <div class="paymentForm__payment__input--inner">
                                        <input class="paymentForm__payment__input" placeholder="Input your garantex code here" v-model="transactionInput" />
                                    </div>

                                </div>
 
                                <div class="paymentForm__payment__button--inner pay" @click="checkGarantexRenew">
                                    <div class="paymentForm__payment__button" >Renew</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!--
                <div class="paymentForm--binance" v-if="payFormStage == 'tron'">
                    <div class="paymentForm__row--binance">
                        <div class="paymentForm__title--container binance">
                            <div class="paymentForm__title binance">To complete the operation, transfer {{this.renewInformation?.Price}} USDT/BUSD</div>
                        </div>
                        <div class="paymentForm__payment--container binance">
                            <div class="paymentForm__payment binance">
                                <div class="paymentForm__payment__column">
                                    <div class="paymentForm__payment__title--inner">
                                        <div class="paymentForm__payment__title">TRC20 Address:</div>
                                    </div>
                                    <div class="paymentForm__payment__address--container">
                                        <div class="paymentForm__payment__address--inner">
                                            <input class="paymentForm__payment__address" disabled :value="renewInformation?.BinanceTrc20" />
                                        </div>
                                        <div class="paymentForm__payment__address__copy--inner">
                                            <img class="paymentForm__payment__address__copy" src="../../assets/dashboard/copyAddress.svg" />
                                        </div>
                                    </div>
                                </div>
                                <div class="paymentForm__payment__column">
                                    <div class="paymentForm__payment__title--inner">
                                        <div class="paymentForm__payment__title input">Input your TxID/TxHash/TxLink</div>
                                    </div>
                                    <div class="paymentForm__payment__input--inner">
                                        <input class="paymentForm__payment__input" placeholder="Input your tx id here" v-model="transactionInput" />
                                    </div>

                                </div>
 
                                <div class="paymentForm__payment__button--inner pay" @click="checkBinanceRenew">
                                    <div class="paymentForm__payment__button" >Renew</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paymentForm__row--binance">
                        <qrcode-vue :value="renewInformation?.BinanceTrc20" :size="size" level="H" />
                    </div>
                </div>   
                -->             
                <div class="paymentForm--solana" :class="{center:!wallet?.connected}"  v-if="payFormStage == 'solana'">
                    <div class="paymentForm__payment--container solana">
                        <div class="paymentForm__payment solana">
                            <div v-if="solanaError == false" class="paymentForm__payment__wallet--inner">
                                <wallet-multi-button  dark></wallet-multi-button>
                            </div>
                            <div  class="paymentForm__payment__button--inner pay" v-if="wallet?.connected && solanaError == false" @click="freeze ? paySolanaRenew(true) : paySolanaRenew(false)">
                                <div class="paymentForm__payment__button"  >Pay {{freeze ? renewInformation?.PriceFreeze : renewInformation?.Price}} USDC</div>
                            </div>
                            <div class="paymentForm__payment__button--inner pay" v-if="wallet?.connected" @click="switchSolanaError">
                                <div class="paymentForm__payment__button"  >{{(solanaError == false) ? "I had an error after pay" : "Back"}}</div>
                            </div>
                            <div v-if="solanaError" class="paymentForm__payment__input--inner">
                                        <input class="paymentForm__payment__input" placeholder="Input your tx id here" v-model="transactionInput" />
                            </div>
                            <div v-if="solanaError"  class="paymentForm__payment__button--inner pay" @click="freeze ? checkSolanaRenew(true) : checkSolanaRenew(false)">
                                <div class="paymentForm__payment__button" >{{freeze ? "Freeze" : "Renew"}}</div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div class="dashboard__rows">
            <div class="dashboard__row">
                <div class="dashboard__row__card first">
                    <div class="user">
                        <div class="user__avatar--wrap">
                            <img :src="discord?.avatar" class="user__avatar"/>
                            <div class="user__discordtag">{{discord?.['full_name']}}</div>
                        </div>
                        <div class="user__rows">
                            <div class="user__row button">
                                <input class="user__key" v-model="user.key" disabled>

                                <div class="user__keycopy button" >
                                    <img @click="copyKey" class="pointer" src="../../assets/dashboard/copy.svg"/>
                                </div>
                            </div>
                            <div class="user__row">
                                <div class="user__activations--wrap">
                                    <div class="user__activations__title">Activations</div>
                                    <div class="user__activations--inner">
                                        <div class="user__activations">  {{user?.activations?.quantity == 0 ? "Unlimited" : `${user?.activations?.devices?.length}/${user?.activations?.quantity}` }}</div>
                                        <div class="user__keyreset button" >
                                            <img class="user__activationsreset pointer"  @click="reset" src="../../assets/dashboard/reset.svg"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="user__unbind" @click="unbind" v-if="user?.unbindable">
                                    <div class="user__unbind text purple pointer">Unbind</div>
                                </div>
                            </div>
                        </div>                                               
                    </div>
                </div>
            </div>
            <div class="dashboard__row">
                <div class="dashboard__row__card second">

                    <div class="key" :class="{form}">
                            <div class="key__row">
                                <div class="key__field">
                                    <div class="key__field__title--wrap">
                                        <div class="key__field__title">Plan type</div>
                                    </div>
                                    <div class="key__field__input--wrap">
                                        <input class="key__field__input" v-model="user.type" disabled/>
                                    </div>
                                </div>
                                <div class="key__field">
                                    <div class="key__field__title--wrap">
                                        <div class="key__field__title">Renewal price</div>
                                    </div>
                                    <div class="key__field__input--wrap">
                                        <input class="key__field__input" :value="getPriceText" disabled/>
                                    </div>
                                </div>
                            </div>

                            <div class="key__row">
                                <div class="key__field">
                                    <div class="key__field__title--wrap">
                                        <div class="key__field__title">Join date</div>
                                    </div>
                                    <div class="key__field__input--wrap">
                                        <input class="key__field__input" :value="getJoinDate"  disabled/>
                                    </div>
                                </div>
                                <div class="key__field">
                                    <div class="key__field__title--wrap">
                                        <div class="key__field__title">Renewal date</div>
                                    </div>
                                    <div class="key__field__input--wrap">
                                        <input class="key__field__input" :value="getRenewalDate" disabled/>
                                    </div>
                                </div>
                            </div>

                            <div class="key__row">
                                <div class="key__field">
                                    <div class="key__field__title--wrap">
                                        <div class="key__field__title">Discord</div>
                                    </div>
                                    <div class="key__field__button--wrap">
                                        <div class="key__field__button" @click="joinDiscord">Join</div>
                                    </div>
                                </div>
                                <div class="key__field">
                                    <div class="key__field__title--wrap">
                                        <div class="key__field__title">Renewal</div>
                                    </div>
                                    <div class="key__field__button--wrap" @click="showPayForm(false)">
                                        <div class="key__field__button purple" >{{getRenewText}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="key__row ">
                                <div class="key__field ">
                                    <div class="key__field__button--wrap">
                                        <div class="key__field__button purple download" :href="clientStream" @click="download">Download {{latestVersion}}</div>
                                    </div>
                                </div>
                                <div class="key__field ">
                                    <div class="key__field__button--wrap" @click="showPayForm(true)">
                                        <div class="key__field__button purple" >{{getFreezeText}}</div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapState } from "vuex";
import 'solana-wallets-vue/styles.css'
import { WalletMultiButton } from 'solana-wallets-vue'
// import QrcodeVue from 'qrcode.vue'
// const web3 = require('@solana/web3.js');
import * as web3 from "@solana/web3.js";
import { createTransferCheckedInstruction,TOKEN_PROGRAM_ID} from "@solana/spl-token";
// import axios  from "axios";
//https://discord.com/api/oauth2/authorize?client_id=948197676661506048&redirect_uri=http%3A%2F%2Flocalhost%3A3080%2Fapi%2Fauth%2Fredirect&response_type=code&scope=identify%20email%20guilds%20guilds.members.read
import { useWallet } from 'solana-wallets-vue';
export default {
    data(){
        return {
            // SOLANA_MAINNET_USDC_PUBKEY: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
            splToken : new web3.PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"),
            USDC_pubkey : new web3.PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"),
           
            latestVersion:"",
            form:false,
            payForm:false,
            renewInformation: "",
            connection: "",
            nftInfo:"",
            // clientStream:"",
            // currentTime : "",
            freeze:false,
            transactionInput:"",
            solanaError : false,
            payFormStage:'main',
            size: ( window.innerWidth > 940 ) ? 225 : ( window.innerWidth < 430 ) ? 0 : 100 ,
            date:""
        }
    },
    components:{
        WalletMultiButton,
        // QrcodeVue,
    },
    async created(){
        // this.currentTime = Date.now()
        this.setConnection()
        await this.SET_WALLET(useWallet())
        fetch(`https://visionaio.com/api/download/info`,{
                    method:"GET",
                    headers:{
                        "authorization":`Bearer ${this.accessToken}`
            }
            }).then(msg => {
                return msg.json()
            }).then(msg => {
                
                if(!msg?.success)return  this.$router.push({ path: `/bind` })
                var r = msg?.message

                
                this.latestVersion = r?.version
        }).catch(() => {
            this.latestVersion = ""
            this.ADD_ALERT({title: "Failed to get download information",type: "fail", subtitle: "Please check connection and reload page", time: 3})
        })
        fetch(`https://visionaio.com/api/renew/cost`,{
                    method:"GET",
                    headers:{
                        "authorization":`Bearer ${this.accessToken}`
            }
            }).then(msg => {
                return msg.json()
            }).then(msg => {
            
                if(!msg?.success)return  this.$router.push({ path: `/bind` })
                var r = msg?.message
                this.renewInformation = r
                // this.toPubkey = 
            }).catch(async() => {
                this.ADD_ALERT({title: "Failed to get renew information",type: "fail", subtitle: "Please check connection and reload page", time: 3})
        })
        fetch(`https://visionaio.com/api/nft/info`,{
                    method:"GET",
                    headers:{
                        "authorization":`Bearer ${this.accessToken}`
            }
            }).then(msg => {
                return msg.json()
            }).then(msg => {
            
                if(!msg?.success)return  this.$router.push({ path: `/bind` })
                var r = msg?.message
                this.nftInfo = r
                // console.log(this.nftInfo)
                // this.toPubkey = 
            }).catch(async() => {
                this.ADD_ALERT({title: "Failed to get renew information",type: "fail", subtitle: "Please check connection and reload page", time: 3})
        })
  
    },
    computed:{
        ...mapState(["discord","user","accessToken","wallet"]),
        getJoinDate: function(){
            return new Date(Number(`${this.user?.created_at}000`)).toLocaleDateString()
        },
        getRenewText : function(){
            let text 
            if (this.user?.type.includes('trial')){
                text = "Trial can't renew"
                return text
            }
            if (this.user?.discord?.roles.find(el => el?.name === "Lab")){
                text = "Lab can't renew"
                return text
            }
            // if (this.user?.description?.includes("mintAddress")){
            //     text = "Nft renewal is not possible"
            //     return text
            // }
            if(this.nftInfo?.nft){
                if (this.nftInfo?.burn){
                    text = "Burn can't renew"
                    return text
                }
                if (this.nftInfo?.lifetime){
                    text = "lifetime can't renew"
                    return text
                }
   
            }

            if (this.renewInformation.Month > 0 && !this.renewInformation?.Price?.toString()?.toLowerCase()?.includes('lifetime') && Date.now() > (Number(this.user?.expires_in) * 1000 - 259200000)){
                text = "Pay"
                return text
            }

            text = "Paid"
            return text
        },
        getFreezeText : function(){
            let text 
            if (this.user?.type.includes('trial')){
                text = "Trial can't freeze"
                return text
            }
            if (this.user?.discord?.roles.find(el => el?.name === "Key Holder")){
                text = "Key holder can't freeze"
                return text
            }
            if(this.nftInfo?.nft){
                if (this.nftInfo?.burn){
                    text = "Burn can't freeze"
                    return text
                }
                if (this.nftInfo?.lifetime){
                    text = "lifetime can't freeze"
                    return text
                }
            }
            // if (this.user?.description?.includes("mintAddress")){
            //     text = "Nft renewal is not possible"
            //     return text
            // }
            if (this.renewInformation.Month > 0 && !this.renewInformation?.Price?.toString()?.toLowerCase()?.includes('lifetime') && Date.now() > (Number(this.user?.expires_in) * 1000 - 259200000)){
                text = "Pay freeze"
                return text
            }

            text = "Paid"
            return text
        },
        getPriceText : function(){
            try{
                // console.log(JSON.stringify(this.renewInformation))
                if(!this.renewInformation)throw new Error("123")
                let priceText 
                if (!this.renewInformation?.Price){
                    priceText = `Loading price`
                    return priceText
                }
                if(this.nftInfo?.nft){
                    if (this.nftInfo?.burn){
                        priceText = `No pay`
                        return priceText
                    }
                    if (this.nftInfo?.lifetime){
                        priceText = `No pay`
                        return priceText
                    }
                }
                if (this.renewInformation?.Price?.toString()?.toLowerCase()?.includes('lifetime') || this.renewInformation?.Price?.toString()?.toLowerCase()?.includes('trial')){
                    priceText = `No pay`
                    return priceText
                }
                priceText = `${this.renewInformation?.Price} USD / ${this.renewInformation?.PriceRUB} RUB`
                return priceText
            }catch(err){
                let priceText = "Fetch price error"
                return priceText
            }

        },
        getRenewalDate: function(){
            return this.renewInformation?.Price?.toString()?.toLowerCase()?.includes('lifetime') ? "lifetime" : new Date(Number(`${this.user?.expires_in}000`)).toLocaleDateString()
        },


    },
    mounted(){
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeUnmount() { 
        window.removeEventListener('resize', this.onResize); 
    },
    methods:{
        ...mapActions(["logoutDiscord","getUser","SET_WALLET","ADD_ALERT"]),
        onResize : function() {
            this.size = ( window.innerWidth > 940 ) ? 225 : ( window.innerWidth < 430 ) ? 0 : 100
        },
        setConnection : async function(){
            return this.connection = await new web3.Connection("https://mainnet.helius-rpc.com/?api-key=1d0cf609-fe82-4a5c-a3de-b0b5c4bccdd8")
        },
        redirectLanding : function (){
            return this.$router.push({ path: `/` })
        },
        showPayForm : async function(freeze){
            // if(this.user.description.includes("mintAddress"))return
            if (this.user?.discord?.roles.find(el => el?.name === "Lab"))return
            if (freeze){
                if (this.user?.discord?.roles.find(el => el?.name === "Key Holder"))return
            }
            if(this.user.type.includes("trial"))return
            if(!this.renewInformation.Month)return
            if(this.renewInformation.Month <= 0)return
            if(Date.now() < (Number(this.user?.expires_in) * 1000 - 259200000))return
            this.freeze = freeze
            this.payForm = this.payForm ? false : true
            this.form = this.form ? false : true
        },
        hidePayForm : async function(){
        
            if(!this.renewInformation.Month)return
            if(this.renewInformation.Month <= 0)return
            this.payForm = this.payForm ? false : true
            this.form = this.form ? false : true
            this.payFormStage = "main"
        },
        showSolana : async function(){
            this.payFormStage = "solana"
        },
        showBinance : async function(){
            this.payFormStage = "binance"
        },
        showTron : async function(){
            this.payFormStage = "tron"
        },
        showGarantex : async function(){
            this.payFormStage = "garantex"
        },
        checkBinanceRenew : async function(){
            try{
                this.ADD_ALERT({title: "Checking tx status", type: "other", subtitle: "Please be patient", time: 3})
                let requestBody = {
                    "transaction":this.transactionInput
                }

                var s = await(await fetch(`https://visionaio.com/api/renew/pay/binance`,{
                        method:"POST",
                        body:JSON.stringify(requestBody),
                        headers:{
                            "authorization":`Bearer ${this.accessToken}`,
                            "content-type":"application/json"
                        }
                })).json()
                if(!s?.success){
                    return this.ADD_ALERT({title: "Payment failed", type: "fail", subtitle: s?.message, time: 3})
                }
                this.ADD_ALERT({title: "Payment done", type: "success", subtitle: "Renew successfull", time: 3})
                this.sleep(1000).then(() => { location.reload() });
                return 
            }catch(err){
                return this.ADD_ALERT({title: "Failed to get tx information",type: "fail", subtitle: "Please check connection and reload page", time: 3})
            }


        },
        checkGarantexRenew : async function(){
            try{
                this.ADD_ALERT({title: "Checking tx status", type: "other", subtitle: "Please be patient", time: 3})
                let requestBody = {
                    "code":this.transactionInput
                }

                var s = await(await fetch(`https://visionaio.com/api/renew/pay/garantex`,{
                        method:"POST",
                        body:JSON.stringify(requestBody),
                        headers:{
                            "authorization":`Bearer ${this.accessToken}`,
                            "content-type":"application/json"
                        }
                })).json()
                if(!s?.success){
                    return this.ADD_ALERT({title: "Payment failed", type: "fail", subtitle: s?.message, time: 3})
                }
                this.ADD_ALERT({title: "Payment done", type: "success", subtitle: "Renew successfull", time: 3})
                this.sleep(1000).then(() => { location.reload() });
                return 
            }catch(err){
                return this.ADD_ALERT({title: "Failed to get tx information",type: "fail", subtitle: "Please check connection and reload page", time: 3})
            }


        },
        switchSolanaError : async function(){
            if (this.solanaError){
                this.solanaError = false
            }else{
                this.solanaError = true
            }
            return
        },
        checkSolanaRenew : async function(freeze){
            try{
                let requestBody = {
                    "transaction":this.transactionInput,
                    "type":"check"
                }
                this.ADD_ALERT({title: "Checking tx status", type: "other", subtitle: "Please be patient", time: 10})
                let action = "renew"
                if (freeze){
                    action = "freeze"
                }
                var b = await(await fetch(`https://visionaio.com/api/${action}/pay/solana`,{
                        method:"POST",
                        body:JSON.stringify(requestBody),
                        headers:{
                            "authorization":`Bearer ${this.accessToken}`,
                            "content-type":"application/json"
                        }
                })).json()
                if(!b.success){ 
                    return this.ADD_ALERT({title: "Your renew is failed", type: "fail", subtitle: b?.message, time: 10})
                }
                this.ADD_ALERT({title: "Your renew is successfully processed!", type: "success", subtitle: "Success!", time: 10})
                this.sleep(1000).then(() => { location.reload() });
            }catch(err){
                this.ADD_ALERT({title: "Your solana payment is failed", type: "fail", subtitle: err?.message, time: 10})
                return
            }
        },
        paySolanaRenew : async function(freeze){
            try{
                this.ADD_ALERT({title: "Awaiting sending tx", type: "other", subtitle: "Please be patient", time: 6})
                if(!this.renewInformation?.SolanaUSDC)throw new Error("No sol address")
                var fromUSDC = await web3.PublicKey.findProgramAddress([this.wallet.publicKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), this.USDC_pubkey.toBuffer()], this.splToken)
                var toUSDC = await web3.PublicKey.findProgramAddress([new web3.PublicKey(this.renewInformation?.SolanaUSDC).toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), this.USDC_pubkey.toBuffer()], this.splToken)
                let amountToPay = freeze ? this.renewInformation.PriceFreeze * 1000000 : this.renewInformation.Price * 1000000
                var transaction = new web3.Transaction()
                    .add(
                    createTransferCheckedInstruction(
                        fromUSDC[0],
                        this.USDC_pubkey,
                        toUSDC[0],
                        this.wallet.publicKey,
                        amountToPay,
                        6,
                        [],
                        TOKEN_PROGRAM_ID

                        /*
                        this.wallet.publicKey, //source
                        fromUSDC[0], // mint
                        this.USDC_pubkey, // dest
                        toUSDC[0], // owner
                        this.renewInformation.Price * 1000000, // amount
                        6, // decimals
                        [], // multisig
                        TOKEN_PROGRAM_ID // programid
                        */
                    )
                );
                const r = await this.wallet.sendTransaction(transaction, this.connection);
                let requestBody = {
                    "transaction":r
                }
                this.ADD_ALERT({title: "Checking tx status", type: "other", subtitle: "Please be patient", time: 10})
                let action = "renew"
                if (freeze){
                    action = "freeze"
                }
                var b = await(await fetch(`https://visionaio.com/api/${action}/pay/solana`,{
                        method:"POST",
                        body:JSON.stringify(requestBody),
                        headers:{
                            "authorization":`Bearer ${this.accessToken}`,
                            "content-type":"application/json"
                        }
                })).json()
                if(!b.success){ 
                    return this.ADD_ALERT({title: "Your renew is failed", type: "fail", subtitle: b?.message, time: 10})
                }
                this.ADD_ALERT({title: "Your renew is successfully processed!", type: "success", subtitle: "Success!", time: 10})
                this.sleep(1000).then(() => { location.reload() });
                return
            }catch(err){
                console.log(err)
                this.ADD_ALERT({title: "Your solana payment is failed", type: "fail", subtitle: err?.message, time: 10})
                this.ADD_ALERT({title: "Your solana payment is failed", type: "fail", subtitle: "Please check connection and reload page", time: 10})
                return
            }



        },
        
        sleep(ms) {
                 return new Promise(resolve => setTimeout(resolve, ms));
        },
        download : async function(){
                // await fetch(`https://visionaio.com/api/download/client?token=${this.accessToken}`,{
                //     method:"GET",
                //     headers:{
                //         "authorization":`Bearer ${this.accessToken}`
                //     }
                // }).then(async response => {
                //     try{
                //         if(response.status == 404)throw new Error("Doesn't exist")
                //         const blob = await response.blob();
                //         const url = await URL.createObjectURL(blob)
                //         const a = document.createElement("a")
                //         a.href = url
                //         a.download = `VisionAIO Setup ${this.latestVersion}.exe`;
                //         document.body.appendChild(a);
                //         a.click();
                //         document.body.removeChild(a);
                //     }catch(err){
                //         this.ADD_ALERT({title: `Download was failed ${err.message}`, type: "fail", subtitle: err?.message, time: 10})
                //     }

                // }).catch( err => {
                //     this.ADD_ALERT({title: `Download was failed ${err.message}`, type: "fail", subtitle: err?.message, time: 10})
                // })
                return  window.open(`https://visionaio.com/api/download/client?token=${this.accessToken}`)
        },
        joinDiscord : async function(){
                let r = await(await fetch(`https://api.cactusweb.io/api/v2/user/license/Vision%20AIO/join-server`,{
                    method:"GET",
                    headers:{
                        "authorization":`Bearer ${this.accessToken}`
                    }
                })).json()
                if(!r?.url){
                    //error
                    return
                }
                return  window.open(r?.url) 
        },
        reset : async function(){
            let r = await(await fetch(`https://api.cactusweb.io/api/v2/user/license/Vision%20AIO/reset`,{
                    method:"GET",
                    headers:{
                        "authorization":`Bearer ${this.accessToken}`
                    }
            }))
            if(r?.status != 200)return this.ADD_ALERT({title: "Failed to reset",type: "fail", subtitle: "Please check connection and reload page", time: 3})
            this.getUser()
            return this.ADD_ALERT({title: "Success",type: "success", subtitle: "Key resetted!", time: 3})
        },
        copyKey : function (){
            this.ADD_ALERT({title: "Success",type: "success", subtitle: "Key copied to clipboard!", time: 3})
            return navigator.clipboard.writeText(this.user.key)
        },
        unbind: async function(){
            let r = await(await fetch(`https://api.cactusweb.io/api/v2/user/license/Vision%20AIO/unbind`,{
                    method:"GET",
                    headers:{
                        "authorization":`Bearer ${this.accessToken}`
                    }
            }))
            if(r?.status == 200){
                //successful unbind
                return  this.$router.push({ path: `/bind` })
                
            }else{
                return this.ADD_ALERT({title: "Failed to unbind",type: "fail", subtitle: "Please check connection and reload page", time: 3})
                //error
            }
            
        },
        logOut : function (){
            this.logoutDiscord()
            return 
        }
    }
}
</script>

<style lang="scss">

    .paymentForm__payment__address--container{
        display: flex;
        flex-direction: row;
    }

    .paymentForm__row--binance{
        display:flex;
        flex-direction:column;
        width:100%;
    }

    .paymentForm__row--binance:nth-child(2){
        justify-content: center;
        align-items: center;
    }


    .paymentForm__payment__address__copy--inner{
        width:45px;
        height:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        border-bottom: 1px solid #313135;
        border-right: 1px solid #313135;
        border-top: 1px solid #313135;
    }



    .paymentForm__payment__address__copy{
        // width:100%;
        // height:100%;
        // cursor:pointer;
        cursor:pointer;
        // justify-content:center;
        // align-items:center;
        // border-left: 1px solid #313135;
        // border-radius: 2px;
    }

    .paymentForm__payment__address{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        padding-left:14px;
        padding-right:23px;
        font-size: 15px;
        line-height: 120%;
        background:#202022;
        display:flex;
        width:100%;
        border: 1px solid #313135;
        border-radius: 2px;
        max-width: 457px;
        justify-content:center;
        align-items:center;
        /* identical to box height, or 18px */


        color: rgba(255, 255, 255, 0.85);

    }


    .paymentForm__payment__address--inner{

        display:flex;
        width:100%;
        max-width: 457px;
        height: 38px;
    }

    .paymentForm__payment__title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        white-space:nowrap;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */


        color: #A4A4A4;

    }

    .paymentForm__payment__title.input{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        white-space:normal;
        /* or 19px */


        color: #FFF9F9;
    }

    .paymentForm__payment__column:nth-child(1){
        margin-top:37px;
    }

    .paymentForm__payment__column{
        display:flex;
        flex-direction:column;
        gap:12px;
    }

    .swv-dropdown{
        width:100%;
    }

    .swv-dropdown-list{
        width:100%;
        z-index:106;
    }

    .swv-button{
        width:100%;
        justify-content:center;
    }

    .swv-button > p{
        flex:1;
    }

    .paymentForm__payment__flex{
        display:flex;
        width:1px;
        flex:0.19;
    }

    .paymentForm__payment__image--container{
        display:flex;
    }

    .paymentForm--flex{
        height:10px;
        width:100%;
        display:flex;
        flex:0.4;
    }

    .paymentForm__payment__image{
        // margin-left:20px;
    }

    .background__form{
        z-index:100;
        background-color:black;
        opacity:0.4;
        position:absolute;
        width:100%;
        height:100%;
    }

    .paymentForm--main{
        display:flex;
        flex-direction:column;
        // height:220px;
        max-width:650px;
        width:100%;
        background: #202022;
        border-radius: 10px;
        padding:33px 31px 54px 31px;
        z-index:105;
    }

    .paymentForm--solana{
        display:flex;
        flex-direction:column;
        height:275px;
        max-width:470px;
        width:100%;
        background: #202022;
        border-radius: 10px;
        padding:30px 34px 32px 33px;
        z-index:105;
    }

    .paymentForm--binance{
        display:flex;
        // height:370px;
        max-width:860px;
        width:100%;
        gap:49px;
        background: #202022;
        border-radius: 10px;
        padding:35px 35px 33px 49px;
        z-index:105;
    }

    .paymentForm__payment__input--inner{
        width:100%;

        max-width: 502px;
        height: 38px;
    }

    .paymentForm__payment__input--inner,.paymentForm__payment__wallet--inner{
        z-index:100;
    }


    .paymentForm__payment{
        display: flex;
        flex-direction: column;
        // max-width: 150px;
        width: 100%;
        gap:35px;
    }



    .paymentForm__payment.solana{
        gap:36px;
        max-width: none;

    }

    .paymentForm__title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        white-space:nowrap;
        line-height: 120%;
        /* identical to box height, or 30px */


        color: rgba(255, 249, 249, 0.9);
    }

    .paymentForm__title.binance{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        white-space:normal;
        /* identical to box height, or 24px */


        color: rgba(255, 249, 249, 0.9);
    }

    .paymentForm__title--container{
        display: flex;
        justify-content: center;
    }

    .paymentForm__title--container.binance{
        display: flex;
        justify-content: unset;
    }


    .form{
        opacity:0.2;
        z-index:1;
    }

    // .paymentForm{
    //     z-index:100;
    // }

    .paymentForm{
        position:absolute;
        display: flex;
        flex-direction: column;
        align-items:center;
        width:100%;
        height:100%;
        padding:40px;
    }



    .paymentForm__payment--container{
       
        display:flex;
        flex-wrap:nowrap;
        
        gap:29px;
        &.solana{
            //  margin-top:80px;
        }
        &.main{
            align-items:center;
            margin-top:53px;
            justify-content:center;
            // flex:1;
        }
        &.binance{
            width:100%;
            // justify-content: center;
            // align-items: center;
        }
    }

    .paymentForm__payment__button{
        margin-left:20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        /* identical to box height, or 24px */


        color: #FFFFFF;
    }

    .paymentForm__payment.binance{
        // justify-content: center;
        gap:32px;
        // align-items: center;
    }

    .paymentForm__payment__input{
        text-indent: 14px;
        width:100%;
        height:100%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 120%;
        /* identical to box height, or 18px */

        background: #202022;
        border: 1px solid #313135;
        border-radius: 2px;
        color: #E4E4E4;
    }

    .paymentForm__payment__button--inner{
        cursor:pointer;
        // max-width:150px;
        width:100%;
        height:46px;
        display:flex;
        justify-content:center;
        align-items:center;
        z-index:99;
        background: #8490CB;
        border-radius: 8px;
    }

    .paymentForm__payment__button--inner.pay{
        background: #8490CB;
        border: 1px solid #313135;
        border-radius: 2px;
    }

    .shadow__circle__dashboard__1{
        position: absolute;
        width: 1332px;
        height: 1332px;
        left: -908px;
        top: -504px;
        background: #733EE1;
        opacity: 0.22;
        filter: blur(550px);
        z-index:1;
    }

    .shadow__circle__dashboard__2{
        position: absolute;
        width: 1332px;
        height: 1332px;
        left: 1254px;
        top: 366px;
        background: #733EE1;
        opacity: 0.22;
        z-index:1;
        filter: blur(550px);
    }

    .pointer{
        cursor:pointer;
    }

    .purple{
        background-color:#8490CB !important;
    }

    .key__field.solo{
        width:100%;
        max-width: none;
    }

    .key__row.solo{
        width:100%;
        margin-top:8px;
    }

    .key__field__button--wrap{
        display:flex;
    }

    .key__field__button{
        width: 100%;
        height: 35px;
        color: #FFF9F9;
        background: #7793EF;
        border-radius: 4px;
        display:flex;
        justify-content:center;
        align-items:center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        cursor:pointer;
        user-select: none;
        
    }

    .key__field__title{
        color:#BFBFBF;
        font-size:16px;
        user-select: none;
        white-space:nowrap;
    }

    

    .key__field__input{
        width:100%;
        height:33px;
        background-color:#202022;
        border: 1px solid #313135;
        border-radius: 4px;
        padding: 12.5px 0 12.5px 16px;
    }

    .key__row{
        width:100%;
        display:flex;
        gap:16px;
    }

    .key__field{
        display:flex;
        flex-direction:column;
        max-width:250px;
        width:100%;
        gap:8px;
    }

    .key{
        width:100%;
        height:100%;
        padding:24px;
        display:flex;
        gap:16px;
        height:fit-content;
        flex-wrap:wrap;
    }

    .user__unbind.text{
        align-items:center;
        color:#FFF9F9;
    }

    .user__unbind{
        max-width:304px;
        user-select: none;
        width:100%;
        height:35px;
        background-color:#AFE0B4;
        display:flex;
        justify-content:center;
        align-self: self-end;
        border-radius:4px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .user__unbind.button{
        color:#FFF9F9;
        font-size:16px;
        justify-content:center;
    }

    .user__activations--inner{
        
        width:100%;
        height: 36px;
        border-radius:4px;
        background-color:#202022;
        border: 1px solid #313135;
        color:#FFFFFF;
        justify-content:space-between;
        align-items:center;
        margin:8px 0 0 0;
        padding:8.5px 0 8.5px 16px;
        display:flex;
        
    }

    .user__activations__title{
        color:#BFBFBF;
        font-size:16px;
    }

    .user__activations--wrap{
        max-width: 195px;
        width:100%;
    }

    .user__row{
        display: flex;
        flex-direction: row;
        gap:16px;
        height:100%;
    }

    .user__row.button{
        position:relative;
        width:100%;
    }

    .user__keycopy.button{
        position: absolute;
        top: 11px;
        display: flex;
        padding-right:20px;
        width: 100%;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .user__keyreset.button{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        padding-right:20px;
    }

    .user__keycopy.button > img {

        &:hover{
            transform: scale(1.25,1.25);
        }
        // padding-right:20px;
    }

    .user__keyreset.button > img {
        &:hover{
            transform: scale(1.25,1.25);
        }
    }

    .user__rows{
        padding:16px 0 0 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .user__key{
        
        max-width: 516px;
        width:100%;
        height: 44px;
        border-radius:4px;
        background-color:#202022;
        border: 1px solid #313135;
        color:#FFFFFF;
        padding:12.5px 0 12.5px 16px;
    }

    .user__avatar--wrap{
        display:flex;
        flex-direction:column;
        align-items:center; 
        user-select:none;
    }

    .user__discordtag{
        padding:16px 0 0 0;
        
        color:#FFFFFF;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
    }

    .user__avatar{
        height:106px;
        max-width:106px;
        width:100%;
        border-radius:50%;
    }

    .dashboard__row__card{
        // display:flex;
        position:relative;
        max-width:564px;
        width:100%;
        height:338px;
        background-color:#202022;
        border-radius:8px;
        box-shadow: 0px 8px 32px 0px #00000040;

    }


    .dashboard__rows{
        display:flex;
        align-items: center;
        //flex-wrap:wrap;
        padding:0 25px;
        gap:47px;
        width:100%;
        max-width:1175px;
    }

    .dashboard__row{
        display:flex;
        width:100%;
        flex:1;
    }

    .user{
        padding:24px;
        height:100%; 
        display: flex;
        flex-direction:column;

    }

    .button--inner{
        cursor:pointer;
        color:white;
    }

    // .button{
    //     cursor:pointer;
    // }

    .dashboard{
        min-height: 100vh;
        width: 100%;
        padding:50px 0;
        z-index:100;
        display:flex;
        justify-content:center;
        align-items:center;
        background-color: rgba(18, 18, 18, 0.8);
    }


    .center{
        justify-content:center;
    }

    @media screen and (max-width: 640px) { 
        .dashboard__rows{
            flex-wrap:wrap;
            flex-direction:column;
        }

        .paymentForm__payment--container.main{
            flex-wrap:wrap;
            flex-direction:column;
        }

        .paymentForm__payment__button--inner.main{
            justify-content:unset;
        }

        // .paymentForm__payment__button--inner.pay{
        //     justify-content:unset;
        // }

        .paymentForm__payment__flex{
            width:20px;
            flex:none;
        }

        .paymentForm__title{
            white-space:normal;
        }
    }

    @media screen and (max-width: 785px) { 
        .paymentForm__row--binance:nth-child(2) {
            display:none;
        }
    }

</style>