<template>
    <!-- <header /> -->
    <div class="main-section">
        <div class="main-section__container">
            <h1 class="main-section__title">VISION AIO</h1>
            <p class="main-section__desc">Software for automate purchasing NFT</p>
            <!-- <div class="main-section__form-button">
                <div class="form-btn pointer" >Sold Out</div>
            </div> -->
        </div>
        <!-- <div class="main-section__next--container">
            <div class="main-section__next">
                <img src="../../../assets/landing/section/arrow.svg"/>
            </div>
        </div> -->
    </div>



    

</template>

<script>


export default {
    data(){
        return {
            
        }
    },
    components:{

    },
    methods:{

    }
}
</script>

<style lang="scss">

.main-section__next--container{
    display:flex;
    justify-content:center;
    align-items:flex-end;
    flex:0.53;
    //padding-bottom:223px;
}

.main-section__container{
    display:flex;
    flex-direction:column;
    padding:295px 0 0 134px;
}

.main-section__next{
    width:60px;
    height:60px;
    border-radius:50px;
    border: 0.64px solid rgba(255, 255, 255, 0.2);
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
}

.main-section{
    
    display:flex;
    flex-direction:column;
    gap:32px;
    flex:1;
}

.main-section__title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 98px;
    /* identical to box height, or 102% */
    letter-spacing:1.7px;
    font-feature-settings: 'salt' on, 'liga' off;

    color: #F2F2F2;
}

.main-section__desc{
    padding: 32px 0 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 325;
    font-size: 20px;
    line-height: 32px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #D9DBE1;
}

.main-section__form-button{
    box-sizing: border-box;
    display: flex;
    cursor:pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 8px 15px;
    // align-self:center;
    margin-top:32px;
    width: 132px;
    height: 48px;
    
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.2);
        // border-color:#fffcfc29;
}

.form-btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height, or 126% */

    letter-spacing: 0.171582px;

    /* #FFFFFF */

    color: #FFFFFF;

}


@media (max-width: 400px) {

    .main-section__title{
        font-size: 20.5px !important;
    }

    .main-section__desc{
        font-size: 11.7px !important;
        font-weight: 500 !important;
    }

    .main-section__form-button{
        width: 99.16px !important;
        height: 31px !important;
        margin-top: 47px !important;
    }

    .form-btn{
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 11px !important;
        line-height: 14px !important;
        letter-spacing: 0.171582px !important;
    }

    .main-section__container{
        padding-top:62px !important;
    }

}

@media (max-width: 850px) {

    .main-section__container{
        padding:91px 0 0 0px;
        justify-content:center;
    }

    .main-section__title{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 130%;
        /* identical to box height, or 42px */

        text-align: center;

        /* #FFFFFF */

        color: #FFFFFF;
    }

    .main-section__desc{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18.2392px;
        line-height: 27px;
        /* identical to box height, or 150% */

        text-align: center;
        letter-spacing: 0.182392px;

        /* light text */

        color: #FFFFFF;

        opacity: 0.6;
    }



    .main-section__next--container{
        display:none;
    }

    .main-section__desc{
        padding-top:24px;
    }

    .main-section__form-button{
        box-sizing: border-box;
        display: flex;
        cursor:pointer;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // padding: 8px 15px;
        align-self:center;
        margin-top:66px;
        width: 145.63px;
        height: 56.32px;
        
        border-radius: 5px;
        border: 0.86px solid #FFFFFF
           // border-color:#fffcfc29;
    }

    .form-btn{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 21px;
        /* identical to box height, or 126% */

        letter-spacing: 0.171582px;

        /* #FFFFFF */

        color: #FFFFFF;

    }


}


</style>