<template>
    <!-- <header /> -->
    <div id="FAQ" class="third-section">
        <div class="third-section__title--inner">
            <h1>F.A.Q</h1>
        </div>
        <div class="third-section__questions--inner">
            <div class="third-section__questions" @click="handleTextClick">
                <div class="third-section__questions--container pointer">
                    <img class="third-section__questions__image" src="../../../assets/landing/section/marker.svg"/>
                    <div class="third-section__questions__text">What system operations are supported?</div>
                </div>
                <div class="third-section__questions__desc pointer">
                    <div class="third-section__questions__message">For now only Windows is supported</div>
                </div>
            </div>
            <div class="third-section__questions" @click="handleTextClick">
                <div class="third-section__questions--container pointer">
                    <img class="third-section__questions__image" src="../../../assets/landing/section/marker.svg"/>
                    <div class="third-section__questions__text">How much is renewal cost?</div>
                </div>
                <div class="third-section__questions__desc pointer">
                        <div class="third-section__questions__message">You can see it in your dashboard</div>
                    </div>
            </div>
                        <div class="third-section__questions" @click="handleTextClick">
                <div class="third-section__questions--container pointer">
                    <img class="third-section__questions__image" src="../../../assets/landing/section/marker.svg"/>
                    <div class="third-section__questions__text">Are there any updates included?</div>
                </div>
                <div class="third-section__questions__desc pointer">
                        <div class="third-section__questions__message">Yes , we are always updating our software</div>
                    </div>
            </div>
                        <div class="third-section__questions" @click="handleTextClick">
                <div class="third-section__questions--container pointer">
                    <img class="third-section__questions__image" src="../../../assets/landing/section/marker.svg"/>
                    <div class="third-section__questions__text">How many tasks can I run at once?</div>
                </div>
                <div class="third-section__questions__desc pointer">
                        <div class="third-section__questions__message">Unlimited</div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data(){
        return {
            
        }
    },
    components:{

    },
    methods:{
        handleTextClick: function(e){
            var el
            if(e.target.parentNode.parentNode.classList[0] == "third-section__questions"){
                el = e.target.parentNode.parentNode
            }
            if(e.target.parentNode.classList[0] == "third-section__questions"){
                // console.log('x')
                // console.log(e.target.parentNode.classList)
                el = e.target.parentNode
                // console.log(el)
            }
            if(e.target.parentNode.parentNode.parentNode.classList[0] == "third-section__questions"){
                
                el = e.target.parentNode.parentNode
            }
            console.log(el)
            //console.log(e.target.parentNode.parentNode.classList[0])
            el.lastChild.classList.toggle("visible")
            // console.log(el.childNodes[0])
            el.childNodes[0].firstChild.classList.toggle("visible")
            el.childNodes[0].lastChild.classList.toggle("visible")
            // const accord = el.querySelector(".third-section__questions__desc.pointer")
            // if(el.lastChild.classList.contains("visible")){
            //     accord.style.height = `${accord.querySelector(".third-section__questions__message").offsetHeight}px` 
            // } else {
            //     accord.style.height = "0px"
            // }
        
            //console.log(el.childNodes)
            //e.target.parentNode.lastChild.classList.toggle("visible")
            //console.log(e.target.parentNode.parentNode.lastChild.classList.toggle("visible"))
        }
    }
}
</script>

<style lang="scss">

.third-section__questions__image.visible{
    transform:rotate(90deg);
    transition: transform 1s ease-in-out;
}

.third-section__questions__image{
    // transform:rotate(-90deg);
    transition: transform 1s ease-in-out;
}

.third-section__questions__desc{
    display:none;
    transform:rotate(-90deg);
    transition: transform 1s ease-in-out;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    /* or 200% */

    font-feature-settings: 'salt' on, 'liga' off;

    color: #FFFFFF;

    opacity: 0.7;
}

.third-section__questions__desc.visible{
    animation: fade 1s linear forwards;
    display:flex;
    flex-direction:column;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    /* or 200% */
    padding-left: 41px;
    padding-top: 24px;

    font-feature-settings: 'salt' on, 'liga' off;

    color: #FFFFFF;
    transition: opacity 1s ease-in-out;

    opacity: 0.7;
}

.third-section{
    display:flex;
    flex-direction:column;
}

.third-section__title--inner{
    margin: 0 0 0 133px;
}

.third-section__questions--inner{
    display:flex;
    flex-direction:column;
    gap:25px;
    margin:51px 0 0 178px;
}

.third-section__questions__text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    padding-left:14px;
    line-height: 32px;
    /* identical to box height, or 178% */

    font-feature-settings: 'salt' on, 'liga' off;

    color: #D9DBE1;
    transition: opacity 1s ease-in-out;
    opacity: 0.4;
}

// .third-section__questions__text:hover{
//     opacity: 1;
//     transition: opacity 0.1s ease-in-out;
// }

.third-section__questions__text.visible{
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.third-section__questions--container{
    display:flex;

    // list-style-image: url("../../../assets/landing/section/marker.svg");
}

.third-section__title--inner > h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 47px;
    line-height: 98px;
    /* identical to box height, or 209% */

    font-feature-settings: 'salt' on, 'liga' off;

    color: #F2F2F2;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (max-width: 400px) {
    .third-section__questions{
        padding-right:30px !important;
        
    }

    .third-section__questions__text{
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 11.5px !important;
        line-height: 25px !important;
        font-feature-settings: 'salt' on, 'liga' off !important;
        color: #D9DBE1 !important;
        opacity: 0.4 !important;
    }

    .third-section__questions__desc{
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 9px !important;
        line-height: 20px !important;
        /* or 222% */

        font-feature-settings: 'salt' on, 'liga' off !important;

        color: #FFFFFF !important;

        opacity: 0.7 !important;
    }

    .third-section__questions--inner{
        margin: 13px 0 0 14px !important;
        gap:12px !important;
    }



    // .third-section__questions__image{
    //     width: 20px;
    //     height: 20px;
    // }

    .third-section__title--inner > h1{
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 800 !important;
        font-size: 20.5px !important;
        line-height: 34px !important; 
        font-feature-settings: 'salt' on, 'liga' off !important;
        color: #F2F2F2 !important;
    }

}

@media (max-width: 850px) {
    .third-section__title--inner{
        display:flex;
        margin:0;
        align-self: center;
        // justify-content:flex-end;
    }

    .third-section__questions--inner{
        margin:40px 0 0 70px;
    }

    .third-section__title--inner > h1{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        flex:0.24;
        line-height: 34px;
        /* identical to box height, or 105% */

        font-feature-settings: 'salt' on, 'liga' off;
        display:flex;
        justify-content:flex-end;
        color: #F2F2F2;

    }
}

@keyframes fade {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}



</style>