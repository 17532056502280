<template>
    <div class="circle one">
        <div class="shadow__circle__one"></div>
    </div>
    <div class="circle two">
        <div class="shadow__circle__two"></div>
    </div>
    <div class="background__image vision">
        <img class="background__image__vision" src="../../assets/landing/section/background.svg" />
        <!-- <div class="shadow__circle__two"></div> -->
    </div>
    <div class="background__image gui">
        <img class="background__image__gui" src="../../assets/landing/section/gui.svg" />
    </div>
    <!-- <div class="background__image mobilegui">
        <img class="background__image__mobilegui" src="../../assets/landing/section/mobileGui.svg" />
    </div> -->
    <div class="landing">
        <section class="section section-header section-outer">
            <!-- <div class="section-inner"> -->
            <LandingHeader ></LandingHeader>
            <FirstSection></FirstSection>
            
            <!-- </div> -->
        </section>
        <section class="section section-outer">
            <SecondSection></SecondSection>
        </section>
        <section class="section section-outer-half">
            <ThirdSection></ThirdSection>
        </section>
        <section class="section section-footer">
            <LandingFooter></LandingFooter>
        </section>
        
    </div>
    
  <!-- <div class="wrap"> 
      <div class="button--inner">
          <div class="button" @click="redirectDashboard">Dashboard</div>
      </div>
  </div> -->
      <!-- <div class="form">
          <div class="form__title">Hi ! Enter wallet server ip to manage it!</div>
          <div class="form__inner">
            <input v-model="formInput" class="form__input"/>
            <div class="form__button" @click="redirectManage">
                Manage
            </div>
          </div>
      </div>
        </div> -->


    

</template>
/* eslint-disable */
<script>

//https://discord.com/api/oauth2/authorize?client_id=948197676661506048&redirect_uri=http%3A%2F%2Flocalhost%3A3080%2Fapi%2Fauth%2Fredirect&response_type=code&scope=identify%20email%20guilds%20guilds.members.read
import LandingHeader from './header/header.vue'
import FirstSection from './firstsection/firstsection.vue'
import SecondSection from './secondsection/secondsection.vue'
import ThirdSection from './thirdsection/thirdsection.vue'
import LandingFooter from './footer/footer.vue'

export default {
    data(){
        return {
            
        }
    },
    components:{
        LandingHeader,
        FirstSection,
        SecondSection,
        ThirdSection,
        LandingFooter
    },
    methods:{
        // redirectDashboard : function (){
        //     // if(this.formInput.split(".") !== 4){
        //     //     alert("bad input")
        //     //     return
        //     // }
        //     return this.$router.push({ path: `/dashboard` })
        // }
    }
}
</script>

<style lang="scss">

    .background__image.gui{
        right:0;
    }

    .background__image.mobilegui{
        right:0;
        display:none;
        bottom: 573px;
        height:100%;
    }



    .background__image{
        z-index:95;
        position: absolute;
        &.vision{
            width: 100%;
            // overflow: hidden;
            right: 50%;
            top: 115px;
        }
        
        &__vision{
            width: 100%;
            transform: scale(1.9);
            // height: 60%;
        }
    }

    

    .circle.one{
        position: absolute;
        right:0;
        background: #733EE1;
        // top: 263px;
        opacity: 0.3;
        filter: blur(550px);
        border-radius:50%;
    }

    .circle.two{
        position: absolute;
        left:0;
        bottom: 0;
        background: #121212;
        filter: blur(250px);
        border-radius:50%;
    }


    .shadow__circle__one{
        // position: absolute;
        width: 1332px;
        height: 1332px;

        z-index:95;
        background: #733EE1;
        opacity: 0.3;
        
    }

    .shadow__circle__two{
        // position: absolute;
        width: 1332px;
        height: 1332px;

        z-index:95;
        background: #121212;
        
    }

    .landing{
        width:100%;
        min-height:100vh;
        z-index:100;
        position:relative;
    }

    .section.section-header.section-outer{
        width:100%;
        min-height:100vh;
        display:flex;
        flex-direction:column;
    }
    
    .section.section-outer{
        width:100%;
        // min-height:100vh;
        display:flex;
        flex-direction:column;
    }

    .section.section-outer-half{
        width:100%;
        margin-top:94px;
        padding-bottom: 419px;
        // min-height:74vh;
        display:flex;
        flex-direction:column;
    }

    .section.section-footer{
        width:100%;
        min-height:20vh;
        display:flex;
        flex-direction:column;
    }

    // @media (min-width: 1800px)  {

    //     .background__image.vision{
    //         right: 74px;
    //         top: -399px;
    //         transition: 0.5s ease-in;
    //     }

    //     .background__image__vision{
    //         transform: scale(1.1);
    //     }

    // }

    // @media (min-width: 1965px)  {

    //     .background__image.vision{
    //         right: 423px;
    //         top: -414px;
    //         transition: 0.5s ease-in;
    //     }

    //     .background__image__vision{
    //         transform: scale(1.2);
    //     }

    // }
    
    @media screen and (max-width: 2500px) {
        .background__image{
            &.vision{
                width: 100%;
                right: 50%;
                top: 151px;
            }
            
            &__vision{
                width: 100%;
                transform: scale(1.9);
            }
        }
    }
    
    @media screen and (max-width: 1301px){
        .background__image{
            &.gui{
                top: 130px;
                right: -150px;
            }
        }
        
        
    }
    
    @media screen and (max-width: 950px){
        .background__image{
            &.gui{
                max-width: 90%;
                &>img{
                    
                }
            }
        }
    }
    
    @media screen and (max-width: 851px){
        .background__image{
            &.gui{
                top: 250px;
                max-width: 100%;
                right: 0;
                &>img{
                    width: 100%;
                }
            }
        }
    }

    // @media (max-width: 400px) {
    //     // .background__image.mobilegui{
    //     //     top:-721px !important;
    //     // }

    //     .section.section-outer-half{
    //         margin-top:27px !important;
    //         padding-bottom: 165px !important;
    //     }



    // }

    @media (max-width: 850px) {
        .background__image.gui{
            display:none;
        }
        
        .background__image.vision{
            display:none;
            // opacity: 0.15;
        }

        .section.section-header.section-outer {
            min-height: 68vh;
        }

        .section.section-outer-half{
            margin-top:90px;
        }

        .section.section-footer{
            width:100%;
            min-height:60vh;
            display:flex;
            flex-direction:column;
        }

    }




</style>