import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store"
import { PhantomWalletAdapter, SolflareWalletAdapter} from '@solana/wallet-adapter-wallets'
import { initWallet } from 'solana-wallets-vue'

const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
  ]

initWallet({ wallets, autoConnect: true })

createApp(App)
.use(store)
.use(router)
.mount('#app')
