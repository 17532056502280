<template>
    <!-- <header /> -->
    <header class="header">
        <div class="header__name--container">
            <img class="header__img" src="../../../assets/landing/header/vision.svg"/>
            <div class="header__name pointer" >Vision AIO</div>
        </div>
        <nav class="header__nav--primary">
            <ul class="header__list">
                <li class="header__list__element pointer" @click="moveTo('Sites')">Sites</li>
                <li class="header__list__element pointer" @click="moveTo('FAQ')">FAQ</li>
                <!-- <li class="header__list__element pointer">Contacts</li> -->
            </ul>
        </nav>

        <nav class="header__nav--secondary">
            <div class="header__button--container">
                <div class="header__button pointer" src="../../../assets/landing/header/dashboard.svg"  @click="redirectDashboard">Dashboard</div>
            </div>
        </nav>
        <nav class="header__nav--primary mobile">
            <img src="../../../assets/landing/header/nav.svg"/>
            <!-- <ul class="header__list">
                <li class="header__list__element pointer">Sites</li>
                <li class="header__list__element pointer">FAQ</li>
                <li class="header__list__element pointer">Contacts</li>
            </ul> -->
        </nav>
    </header>



    

</template>

<script>


export default {
    data(){
        return {
            
        }
    },
    components:{

    },
    methods:{
        redirectDashboard: function (){
            return this.$router.push({ path: `/dashboard` })
        },
         moveTo(elementId){
            const id = document.getElementById(elementId)

            if (!id){
                return
            }

            id.scrollIntoView({behavior: 'smooth'})
        },
    }
}
</script>

<style lang="scss">

    .header{
        display:flex;
        flex-direction:row;
        //justify-content:center;
        padding: 48px 130px 0 130px;
    }



    .header__name--container{
        display:flex;
        // flex:0.65;
        flex: 1;
        align-items: center;
    }

    .header__name{
        padding: 0 0 0 8px;
        white-space:nowrap;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 21.2243px;
        line-height: 25px;
        color: rgba(255, 255, 255, 0.9);
    }

    .header__nav--primary{
        flex:0;
        display:flex;
        padding-left:30px;
        align-items:center;
    }

    .header__list{
        display:flex;
        flex-direction:row;
        gap:106px;
    }

    .header__list__element{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .header__nav--primary.mobile{
        display:none;
    }

    .header__nav--secondary{
        padding-left:30px;
        flex:1;
        display:flex;
        justify-content:flex-end;
    }

    .header__button{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #DDDDDD;
        &--container{
            box-sizing: border-box;
            display: flex;
            cursor:pointer;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 15px;
            width: 120px;
            height: 48px;
            background: linear-gradient(39.2deg, rgba(121, 199, 247, 0.3) 8.6%, rgba(115, 62, 225, 0.3) 94.59%);
            
            border-radius: 5px;
            border: 2px solid;

            border-color:rgba(255, 255, 255, 0.2);




            //border-color:linear-gradient(39.2deg, rgba(121, 199, 247, 0.3) 8.6%, rgba(115, 62, 225, 0.3) 94.59%)
            // border-image: linear-gradient(284.74deg, rgba(58, 192, 253, 0.16) -4.08%, rgba(255, 255, 255, 0) 44.93%),linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
        }
    }

    @media (max-width: 400px) {

        .header__img{
            height: 14.953347206115723px;
            width: 18.848966598510742px;

        }

        .header{
            padding: 19px 12px 0 31px !important;
        }

        .header__name{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 325;
            padding: 0 0 0 6px !important;
            font-size: 13.5px;
            line-height: 16px;
            color: rgba(255, 255, 255, 0.9);
        }

        .header__button--container{
            height:33.3px !important;
            max-width:128px !important;
        }

        .header__button{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 25px;
            /* identical to box height, or 227% */

            letter-spacing: 0.2px;

            /* light text */

            color: #FFFFFF;

        }

    }

    @media (max-width: 850px) {
        .header__nav--primary{
            display:none;
        }
        .header__button--container{
            max-width: 187px;
            width:100%;
            height: 52px;
        }
        .header{
            padding: 28px 16px 0 30px;
            justify-content:space-between;
        }

        .header__nav--secondary{
            flex:0.5;
        }

        // .header__nav--primary.mobile{
        //     display:flex
        // }

        .header__name--container{
            flex:0.45;
        }


     }




</style>