<template>
    <div class="shadow__circle__1">
    
    </div>
    <div class="shadow__circle__2">
    </div>
    <div class="wrap">
        <div class="bind--wrap">
            <div class="bind__column">
                <div class="bind__column__title--wrap">
                    <div class="bind__column__title">Bind your key</div>
                </div>
            </div>
            <div class="bind__column">
                <div class="bind__column__input--wrap">
                    <input class="bind__column__input" v-model="keyInput" placeholder="Enter your key here" />
                </div>
            </div>
            <div class="bind__column">
                <div class="bind__column__button--wrap">
                    <div class="bind__column__button pointer" @click="bindKey">Bind</div>
                </div>
            </div>
            <div class="bind__column">
                <div class="bind__column__button--wrap">
                    <wallet-multi-button  dark></wallet-multi-button>
                </div>
            </div>
            <div class="bind__column" v-if="getWalletConnection">
                <div class="bind__column__button--wrap" v-if="getWalletConnection">
                    <div class="bind__column__button pointer" @click="verifyWallet">Verify</div>
                </div>
            </div>
        </div>
        
    </div>




    <!-- <div class="paymentForm--solana" :class="{center:!wallet?.connected}"  v-if="payFormStage == 'solana'">
                    <div class="paymentForm__payment--container solana">
                        <div class="paymentForm__payment solana">
                            <div v-if="solanaError == false" class="paymentForm__payment__wallet--inner">
                                <wallet-multi-button  dark></wallet-multi-button>
                            </div>
                            <div  class="paymentForm__payment__button--inner pay" v-if="wallet?.connected && solanaError == false" @click="paySolanaRenew">
                                <div class="paymentForm__payment__button"  >Pay {{this.renewInformation?.Price}} USDC</div>
                            </div>
                            <div class="paymentForm__payment__button--inner pay" v-if="wallet?.connected" @click="switchSolanaError">
                                <div class="paymentForm__payment__button"  >{{(solanaError == false) ? "I had an error after pay" : "Back"}}</div>
                            </div>
                            <div v-if="solanaError" class="paymentForm__payment__input--inner">
                                        <input class="paymentForm__payment__input" placeholder="Input your tx id here" v-model="transactionInput" />
                            </div>
                            <div v-if="solanaError"  class="paymentForm__payment__button--inner pay" @click="checkSolanaRenew">
                                <div class="paymentForm__payment__button" >Renew</div>
                            </div>
                        </div>
                </div> -->
  <!-- <div class="wrap"> 
      <div class="button--inner">
          <div class="button" @click="redirectLanding">Back landing</div>
      </div>
      <div class="form--inner">
          <input class="form--input" v-model="keyInput" />
          <div class="form--button" @click="bindKey">bind</div>
      </div>
      <div class="button--inner">
          <div class="button" @click="logOut">Logout</div>
      </div>
      <img :src="discord?.avatar" class="user__avatar"/>
      <div class="user__discordtag">{{discord?.['full_name']}}</div>

  </div> -->
      <!-- <div class="form">
          <div class="form__title">Hi ! Enter wallet server ip to manage it!</div>
          <div class="form__inner">
            <input v-model="formInput" class="form__input"/>
            <div class="form__button" @click="redirectManage">
                Manage
            </div>
          </div>
      </div>
        </div> -->


    

</template>

<script>

//https://discord.com/api/oauth2/authorize?client_id=948197676661506048&redirect_uri=http%3A%2F%2Flocalhost%3A3080%2Fapi%2Fauth%2Fredirect&response_type=code&scope=identify%20email%20guilds%20guilds.members.read
import { mapActions,mapState } from "vuex";
import { useWallet } from 'solana-wallets-vue';
import 'solana-wallets-vue/styles.css'
import { WalletMultiButton } from 'solana-wallets-vue'
import * as web3 from "@solana/web3.js";
import bs58 from "bs58";

export default {
    data(){
        return {
            keyInput : "",
        }
    },
    components:{
        WalletMultiButton,
    },
    computed:{
        ...mapState(["discord","user","accessToken","wallet"]),
        getWalletConnection: function(){
            let wallet = useWallet()
            // console.log(wallet.connected?.value)
            return wallet.connected?.value
        }
    },
    created(){
        this.setConnection()
        this.SET_WALLET(useWallet())
        // console.log(this.wallet)
    },
    methods:{
        ...mapActions(["logoutDiscord","ADD_ALERT","SET_WALLET"]),
        redirectLanding : function (){

            return this.$router.push({ path: `/` })
        },
        verifyWallet : async function (){
            // console.log(this.wallet)

            let r = await (await fetch(`https://nftapi.visionaio.com/nonce?walletAddress=${this.wallet?.publicKey?.toString()}`,{
                    method:"GET",
            })).json()
            // console.log(wallet.signMessage)
            let signedMessageBuffer = await this.wallet.signMessage(new Uint8Array(Buffer.from(r?.nonce)), "utf8")
            let signatureBaseEncoded = bs58.encode(signedMessageBuffer)
            let r1 = await (await fetch(`https://nftapi.visionaio.com/create-license`,{
                    method:"POST",
                    body:JSON.stringify({
                        "signature": signatureBaseEncoded,
                        "walletAddress": this.wallet?.publicKey?.toString()
                    }),
                    headers:{
                        "authorization":`Bearer ${ this.accessToken}`,
                        "content-type": "application/json",
                    }
            })).json()
            if(r1?.success){
                return this.$router.push({ path: `/dashboard` })
            }
            this.ADD_ALERT({title: "Fail",type: "fail", subtitle: "Failed to verify nft", time: 5})
            // console.log(r1)
            return 
        },
        logOut : function (){
            return this.logoutDiscord()
        },
        setConnection : async function(){
            return this.connection = await new web3.Connection("https://rpc.pinit.io/")
        },
        bindKey : async function(){
            try{
                let r = await(await fetch(`https://api.cactusweb.io/api/v2/user/license/bind`,{
                        method:"POST",
                        body:JSON.stringify({"key":this.keyInput}),
                        headers:{
                            "authorization":`Bearer ${ this.accessToken}`,
                            "content-type": "application/json"
                        }
                }))
                if(r?.status == 200){
                    return  this.$router.push({ path: `/dashboard` })
                }else{
                    //error
                    let msg = (await (r.json()))?.message
                    this.ADD_ALERT({title: "Failed to bind key",type: "fail", subtitle: msg, time: 3})
                    return
                }
            }catch(err){
                this.ADD_ALERT({title: "Failed to bind key",type: "fail", subtitle: err.message, time: 3})
            }

        }
    }
}
</script>

<style lang="scss">

    .shadow__circle__1{
        position: absolute;
        width: 1332px;
        height: 1332px;
        left: -908px;
        top: -504px;
        background: #733EE1;
        opacity: 0.3;
        filter: blur(550px);
        z-index:1;
    }

    .shadow__circle__2{
        position: absolute;
        width: 1332px;
        height: 1332px;
        left: 1254px;
        top: 366px;
        background: #733EE1;
        opacity: 0.3;
        filter: blur(550px);
    }

    .bind__column__button--wrap{
        width:100%;
        max-width:400px;
        height:30px;
        // display:flex;
        // justify-content:center;
        // align-items:center;
    }

    .bind__column__button{
        width:100%;
        height:100%;
        background: #8490CB;
        border-radius: 4px;
        display:flex;
        justify-content:center;
        align-items:center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #FFF9F9;
        user-select: none;  
    }

    .bind__column__title{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #BFBFBF;
        user-select: none;  
    }

    .bind__column__input--wrap{
        max-width:400px;
        width:100%;
        height: 33px;
    }

    .bind__column{
        width:100%;
        display:flex;
        justify-content: center;
        white-space:nowrap;
    }

    .swv-button{
        width:100%;
        justify-content:center;
        height: 30px;
    }

    .bind__column__input{
        width:100%;
        height:100%;
        border: 1px solid #313135;
        border-radius: 4px;
        outline:none;
        background: #202022;
        padding: 0 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #FFFFFF;
        &::placeholder{
            padding: 0 24px;
            user-select: none;  
        }
    }

    .bind--wrap{
        width:100%;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        padding: 24px;
        gap: 20px;
        max-width: 453px;
        height: 265px;
        background: #202022;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }

    .wrap{
        width:100%;
        height:100vh;    
        display:flex;
        position:relative;
        align-items:center;
        justify-content:center;
        z-index:100;    
    }



    .button--inner,.form--button{
        cursor:pointer;
        color:white;
    }


</style>