<template>
    <!-- <header /> -->
    <footer class="footer">

        <div class="footer__columns mobile">
            <div class="footer__column">
                <div class="footer__name--container">
                    <img class="footer__img" src="../../../assets/landing/header/vision.svg"/>
                    <div class="footer__name" >Vision AIO</div>
                </div>
                <div class="footer__nav--container">
                    <div class="footer__nav__title--container">
                        <div class="footer__nav__title">
                            Navigation
                        </div>          
                    </div>
                    <div class="footer__list--container">
                        <div class="footer__list footer__list--menu"  @click="moveTo('Sites')">
                            Sites
                        </div>
                        <!-- <div class="footer__list">
                            Partners
                        </div> -->
                        <div class="footer__list footer__list--menu"  @click="moveTo('FAQ')">
                            FAQ
                        </div>
                        <!-- <div class="footer__list">
                            Contacts
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="footer__column">
                <div class="footer__agreements">
                    <!-- <div class="footer__agreement">User Agreement</div> -->
                    <!-- <div class="footer__agreement">Privacy Policy</div> -->
                </div>
            </div>
            <div class="footer__column">
                <div class="footer__socails">
                    <div class="footer__social">
                        <img class="footer__list--menu" @click="openLink('https://twitter.com/vision_aio')" src="../../../assets/landing/header/twitter.svg"/>
                    </div>
                    <!-- <div class="footer__agreement">Privacy Policy</div> -->
                </div>
            </div>

        </div>

        <div class="footer__rows">
            <div class="footer__row">
                <div class="footer__name--container">
                    <img class="footer__img" src="../../../assets/landing/header/vision.svg"/>
                    <div class="footer__name" >Vision AIO</div>
                </div>
                <h3 class="footer__description"></h3>
                <div class="footer__icons--inner">
                    <img class="pointer" @click="openLink('https://vk.com/visionaio')" src="../../../assets/landing/section/vk.png"/>
                    <img class="pointer" @click="openLink('https://twitter.com/vision_aio')" src="../../../assets/landing/section/twitter.png"/>
                </div>
            </div>
            <div class="footer__row">
                <div class="footer__row__title--inner">
                    <div class="footer__row__title" >Quick Links</div>

                </div>
                <ul class="footer__row__list">
                    <li class="footer__row__list__element footer__list--menu" @click="moveTo('Sites')">Sites</li>
                    <!-- <li class="footer__row__list__element">Partners</li> -->
                    <li class="footer__row__list__element footer__list--menu"  @click="moveTo('FAQ')">FAQ</li>
                    <!-- <li class="footer__row__list__element">Contacts</li> -->
                </ul>
            </div>
            <!-- <div class="footer__row"> -->
                <!-- <div class="footer__row__title--inner">
                    <div class="footer__row__title" >Services</div>

                </div>
                <ul class="footer__row__list">
                    <li class="footer__row__list__element">Lorem ipsum</li>
                    <li class="footer__row__list__element">Lorem ipsum</li>
                    <li class="footer__row__list__element">Lorem ipsum</li>
                    <li class="footer__row__list__element">Lorem ipsum</li>
                </ul> -->
            <!-- </div> -->
            <!-- <div class="footer__row">
                <div class="footer__row__title--inner">
                    <div class="footer__row__title" >Reach us</div>

                </div>
                
                <div class="footer__row__contacts">
                    <div class="footer__row__contact">
                        <img class="footer__row__contact__image" src="../../../assets/landing/section/email-icon.svg"/>
                        <a class="footer__row__contact__text" target="_blank" href="mailto:help@visionaio.com">help@visionaio.com</a>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="footer__columns">
            <div class="footer__column rights">
                <div class="footer__text">© 2023. All rights reserved</div>
            </div>
            <div class="footer__column lists">
                <ul class="footer__list">
                    <!-- <li>Terms & Conditions</li>
                    <li>|</li>
                    <li>Privacy Policy</li> -->
                    <!-- <li>|</li>
                    <li>Sitemap</li>
                    <li>|</li>
                    <li>Disclaimer</li> -->
                </ul>
            </div>
        </div>

    </footer>



    

</template>

<script>


export default {
    data(){
        return {
            
        }
    },
    components:{

    },
    methods:{
        moveTo(elementId){
            const id = document.getElementById(elementId)

            if (!id){
                return
            }

            id.scrollIntoView({behavior: 'smooth'})
        },
        openLink(link){
            window.open(link, "_blank")
        }
    }
}
</script>

<style lang="scss">





    .footer__column.rights{
        display:flex;
    }

    .footer__column.lists{
        margin:0 165px 0 0;
    }

    .footer__columns{
        display:flex;
        width:100%;
        margin: 126px -14px 26px 0 ;
        justify-content:space-between;
    }

    .footer__text{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        white-space:nowrap;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #D9DBE1;
    }
    
    .footer__list{
        display:flex;
        gap:8px;
        white-space:nowrap;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #D9DBE1;

        &--menu{
            cursor: pointer;
        }

        // flex-direction:column;
    }
    // .footer_list{
    //     display:flex;
    //     flex-direction:column;
    // }

    .footer__row__contact{
        display:flex;
        flex-direction:row;
        align-items:center;
        align-self:center;
        margin-top:24px;
        gap:8px;


    }

    .footer__row__contact__text{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #D9DBE1;
    }

    .footer__row__title{
        color:#FFFFFF;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        white-space:nowrap;
        line-height: 30px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #FFFFFF;
    }

    .footer__row__list{
        margin-top:24px;
        display:flex;
        flex-direction:column;
        gap:12px;
    }

    .footer__row__list__element{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #D9DBE1;
    }

    .footer__icons--inner{
        display:flex;
        gap:16px;
        margin-top:24px;
    }

    .footer__description{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #D9DBE1;
        margin-top:23px;
    }

    .footer__rows{
        display:flex;
        flex-direction:row;
        gap:30px;
        width:100%;
    }


    .footer__row{
        display:flex;
        flex-direction:column;
        width:100%;
    }

    .footer__name--container{
        display:flex;
        align-items: center;
        // justify-content:center;
    }

    .footer__name{
        padding: 0 0 0 8px;
        white-space:nowrap;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 21.2243px;
        line-height: 25px;
        color: rgba(255, 255, 255, 0.9);
    }

    .footer{
        display:flex;
        flex-direction:column;
        margin:15px 0 0 165px;
        padding-right:30px;
        
    }

    .footer__columns.mobile{
        display:none;
    }

    @media (max-width:400px){
        .footer__img{
            height: 21.625032424926758px !important;
            width: 27.600515365600586px !important;
        }

        .footer__name{
            font-family: 'Inter' !important;
            font-style: normal !important;
            font-weight: 325 !important;
            font-size: 13.58px !important;
            line-height: 16px !important;

            color: rgba(255, 255, 255, 0.9);
        }

        .footer__nav__title{
            font-family: 'Gotham' !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 13.5px !important;
            line-height: 31px !important;
            letter-spacing: 0.103673px !important;
            color: #FFFFFF;
            
        }

        .footer__list{
            font-family: 'Poppins' !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 10.2px !important;
            line-height: 15px !important;
            color: #FFFFFF;

        }

        .footer__nav--container{
            margin-top:44px !important;
        }

        .footer__agreement{
            font-family: 'Poppins' !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 10.2px !important;
            line-height: 15px !important;
            color: #FFFFFF;
        }

        .footer__list:nth-child(1){
            margin-top:26.24px !important;
        }

        .footer__list--container{
            gap:13.39px !important;
        }

        .footer__social{
            width:27px !important;
            height:27px !important;
        }

    }

    @media (max-width: 850px) {

        .footer__name--container{

            justify-content:center;
        }

        .footer{
            margin:0;
            padding:0;
        }

        .footer__rows{
            display:none
        }

        .footer__columns{
            display:none;
            
        }

        .footer__columns.mobile{
            display:flex;
            justify-content:center;
            margin:12px 0 ;
            flex-direction:column;
        }

        .footer__list--container{
            display:flex;
            flex-direction:column;
            gap:19.7px
        }

        .footer__list{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */


            /* #FFFFFF */

            color: #FFFFFF;
        }

        .footer__nav__title{
            font-family: 'Gotham';
            font-style: normal;
            font-weight: 500;
            font-size: 20.7346px;
            line-height: 31px;
            /* identical to box height, or 150% */

            letter-spacing: 0.103673px;

            /* light text */

            color: #FFFFFF;
        }

        .footer__nav--container{
            margin-top:77px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .footer__list:nth-child(1){
            margin-top:43.47px;
        }

        .footer__list{
            display: flex;
            justify-content: center;
        }

        .footer__agreements{
            margin-top:58px;
        }

        .footer__agreements{
            display:flex;
            justify-content:center;
        }

        .footer__agreement{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */


            /* light text */

            color: #FFFFFF;
        }

        .footer__socails{
            display: flex;
            justify-content: center;
            margin-top: 56px;
        }

    }

</style>